import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  CalendarToday,
  CalendarViewDay,
  Close,
  CloudDownload,
  Description,
  Email,
  FileCopy,
  Fingerprint,
  FlipToBackOutlined,
  Map,
  OpenInBrowser,
  OpenInNew,
  Phone,
  Refresh,
  Search,
  TransferWithinAStation,
  Visibility,
  ZoomOutMapOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import toastr from "toastr";
import _ from "underscore";
import Loader from "../common/loader";
import { downloadWithdrawReport } from "../helpers/PDFGenerator";
import {
  IsNullOrEmpty,
  getBlockChainUrl,
  getLogo,
  getTransactionUrl,
  pageSize,
  paginate,
} from "../helpers/utility";
import { getUserInfo } from "../store/auth/auth.selector";
import {
  fetchWithdrawRequest,
  restartWithdrawRequest,
} from "../store/withdraw/withdraw.action";
import WithdrawAPI from "../store/withdraw/withdraw.api";
import { getWithdraws } from "../store/withdraw/withdraw.selector";

function Withdraw() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [datum, setDatum] = useState({});
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState(moment().format("DD-MM-YYYY"));

  const { withdraws, isLoading, user } = useSelector((state) => {
    return {
      withdraws: getWithdraws(state),
      user: getUserInfo(state),
      isLoading: state.WithdrawReducer.isLoading,
    };
  });

  useEffect(() => {
    if (selectedItem) {
      let z = _.find(withdraws, (x) => x.id === selectedItem);
      setDatum(z);
    } else {
      setDatum({});
    }
  }, [selectedItem]);

  useEffect(() => {
    dispatch(
      fetchWithdrawRequest({
        status,
        startDate: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        endDate: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      })
    );
  }, [status, startDate, endDate]);

  let filteredList = withdraws;
  if (!filteredList) {
    filteredList = [];
  }

  if (!IsNullOrEmpty(searchText)) {
    filteredList = filteredList.filter(
      (x) =>
        x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.cid.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.txnid.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
        x.transactionhash.toLowerCase().indexOf(searchText.toLowerCase()) >
          -1 ||
        x.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
  }

  filteredList = _.sortBy(filteredList, (x) =>
    moment.unix(x.createdOn._seconds)
  ).reverse();
  let totalPages = Math.ceil(filteredList.length / pageSize);
  filteredList = paginate(filteredList, pageSize, page);
  return (
    <div>
      {isLoading && <Loader />}
      <Card className="mt-1">
        <CardHeader
          title="Withdraws"
          subheader={"List of Withdraws"}
          avatar={<TransferWithinAStation />}
          action={
            <Button
              variant="outlined"
              className="float-right"
              color="primary"
              startIcon={<CloudDownload />}
              onClick={() => {
                downloadWithdrawReport(filteredList, user);
              }}
            >
              Download
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                className="rounded-lg"
                style={{ marginTop: 0, marginRight: 16 }}
                margin="dense"
                size="small"
                fullWidth
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DateRangePicker
                initialSettings={{
                  showDropdowns: true,
                  startDate: startDate,
                  endDate: endDate,
                  maxDate: moment(),
                  locale: {
                    format: "DD-MM-YYYY",
                  },
                }}
                onApply={(e, val) => {
                  setStartDate(moment(val.startDate).format("DD-MM-YYYY"));
                  setEndDate(moment(val.endDate).format("DD-MM-YYYY"));
                }}
              >
                <Button fullWidth variant="contained" color="primary">
                  {moment(startDate, "DD-MM-YYYY").format("LL")} -{" "}
                  {moment(endDate, "DD-MM-YYYY").format("LL")}
                </Button>
              </DateRangePicker>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {filteredList.map((dep) => {
                  let fees = dep.transactionChargeFees;
                  if (!fees) {
                    dep.transactionChargeFees = parseFloat(
                      dep.transactionCharge / dep.coinValue
                    ).toFixed(8);
                  }
                  return (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Card
                        variant="outlined"
                        style={{ opacity: dep.status === "Approved" ? 1 : 0.7 }}
                      >
                        <CardHeader
                          avatar={
                            <img
                              style={{ height: 32, width: 32 }}
                              src={getLogo(dep.type).logo}
                            />
                          }
                          classes={{
                            root: "p-1",
                            action: "px-3 py-2",
                          }}
                          title={dep.status}
                          subheader={`${dep.amount} USD`}
                          action={
                            <Visibility
                              className="cursor"
                              color="info"
                              onClick={() => {
                                setSelectedItem(dep.id);
                              }}
                            />
                          }
                          style={{
                            background:
                              dep.status === "Approved" ? "#bcf5bc" : "inherit",
                          }}
                        />
                        <Divider />
                        <CardContent className="p-0">
                          <table className="table-striped text-medium border-none">
                            <tr>
                              <td>
                                Transfered{" "}
                                {dep.tokenSymbol ? "Tokens" : "Coins"}
                              </td>
                              <td>
                                {dep.transactionhash ? (
                                  <a
                                    className="text-primary font-weight-bolder text-decoration-none"
                                    target="_blank"
                                    href={
                                      getTransactionUrl(dep.type) +
                                      dep.transactionhash
                                    }
                                  >{`${dep.coins} ${
                                    dep.tokenSymbol || dep.type
                                  }`}</a>
                                ) : (
                                  `${dep.coins} ${dep.tokenSymbol || dep.type}`
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Transaction Charge</td>
                              <td>
                                {dep.parentTransactionHash ? (
                                  <a
                                    className="text-info font-weight-bolder text-decoration-none cursor"
                                    target="_blank"
                                    href={
                                      getTransactionUrl(dep.type) +
                                      dep.parentTransactionHash
                                    }
                                  >{`${dep.transactionChargeFees} ${dep.type}`}</a>
                                ) : (
                                  `${dep.transactionChargeFees} ${dep.type}`
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Transaction Time</td>
                              <td>
                                {moment
                                  .unix(dep.createdOn._seconds)
                                  .format("lll")}
                              </td>
                            </tr>
                          </table>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid xs={12} sm={12}>
              {filteredList.length > 0 && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="my-2"
                >
                  <Pagination
                    shape="rounded"
                    variant="outlined"
                    count={totalPages}
                    page={page}
                    color="primary"
                    onChange={(e, pg) => {
                      setPage(pg);
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {datum.id && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={!IsNullOrEmpty(selectedItem) && datum.id}
        >
          <DialogTitle>
            Withdraw Information
            <Close
              className="float-right mt-2"
              onClick={() => {
                setSelectedItem("");
              }}
            />
          </DialogTitle>
          <Divider />
          <DialogContent>
            {isLoading && <Loader />}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Customer ID" primary={datum.cid} />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.cid);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBox />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer Name"
                      primary={datum.name}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.name);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Email />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer Email"
                      primary={datum.email}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.email);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Phone />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Customer Mobile"
                      primary={datum.mobile}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.mobile);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalance />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Transaction Coins"
                      primary={`${datum.coins} ${
                        datum.tokenSymbol || datum.type
                      }`}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.coins);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalanceWallet />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Transaction Charge"
                      primary={`${datum.transactionCharge} ${
                        datum.tokenSymbol || datum.type
                      } / ${datum.transactionChargeFees} ${datum.type}`}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              datum.transactionCharge
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Transaction ID"
                      primary={datum.id}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.id);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Reference #"
                      primary={datum.txnid}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.txnid);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Map />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="From IP Address"
                      primary={datum.domainName}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.domainName);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <ZoomOutMapOutlined />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary="Status" primary={datum.status} />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(datum.status);
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Created On"
                      primary={moment
                        .unix(datum.createdOn._seconds)
                        .format("lll")}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              moment
                                .unix(datum.createdOn._seconds)
                                .format("lll")
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarViewDay />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Last Modified On"
                      primary={moment
                        .unix(datum.updatedOn._seconds)
                        .format("lll")}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <FileCopy
                          color="primary"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              moment
                                .unix(datum.updatedOn._seconds)
                                .format("lll")
                            );
                            toastr.success("Copied to clipboard");
                          }}
                        />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <OpenInBrowser />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Withdraw Address"
                      primary={datum.address}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <a
                          target="_blank"
                          href={getBlockChainUrl(datum.type) + datum.address}
                        >
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <OpenInBrowser />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Admin Address"
                      primary={datum.toAddress}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <a
                          target="_blank"
                          href={getBlockChainUrl(datum.type) + datum.toAddress}
                        >
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              {datum.tokenAddress && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <List disablePadding>
                    <ListItem disableGutters>
                      <ListItemAvatar>
                        <Avatar>
                          <FlipToBackOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        secondary="Token Address"
                        primary={datum.tokenAddress}
                      />
                      {navigator && navigator.clipboard && (
                        <ListItemSecondaryAction>
                          <a
                            target="_blank"
                            href={
                              getBlockChainUrl(datum.type) + datum.tokenAddress
                            }
                          >
                            <OpenInNew color="primary" />
                          </a>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </List>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Transaction Hash"
                      primary={datum.transactionhash}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <a
                          target="_blank"
                          href={
                            getTransactionUrl(datum.type) +
                            datum.transactionhash
                          }
                        >
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <Fingerprint />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Transaction Charge Hash"
                      primary={datum.parentTransactionHash}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <a
                          target="_blank"
                          href={
                            getTransactionUrl(datum.type) +
                            datum.parentTransactionHash
                          }
                        >
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar>
                        <OpenInBrowser />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Webhook Url"
                      primary={datum.webhookUrl}
                    />
                    {navigator && navigator.clipboard && (
                      <ListItemSecondaryAction>
                        <a target="_blank" href={datum.webhookUrl}>
                          <OpenInNew color="primary" />
                        </a>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {datum.status === "Approved" && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Refresh />}
                onClick={() => {
                  WithdrawAPI.SendHook(datum.id).then((x) => {
                    Swal.fire({
                      title: "Withdraw Hook",
                      text: "Details send successfully",
                    });
                  });
                }}
              >
                Resend Webhook
              </Button>
            )}
            {datum.status === "Requested" && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Refresh />}
                onClick={() => {
                  dispatch(restartWithdrawRequest(datum.id));
                }}
              >
                Restart Transaction
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default Withdraw;
