import { call, put, takeEvery } from "redux-saga/effects";
import * as DepositActions from "./deposit.action";
import DepositAPI from "./deposit.api";
import { DepositActionTypes } from "./deposit.model";

export function* handleFetchDeposit(action) {
  try {
    const response = yield call(DepositAPI.GetDeposits, action.payload);
    yield put(DepositActions.fetchDepositSuccess(response));
  } catch (e) {
    yield put(DepositActions.fetchDepositFailure(e));
  }
}

export function* handleRestratDeposit(action) {
  try {
    debugger;
    yield call(
      DepositAPI.SendHook,
      action.payload.id,
      action.payload.hash,
      action.payload.fromAddress
    );
    yield put(DepositActions.restartDepositSuccess(action.payload));
  } catch (e) {
    yield put(DepositActions.restartDepositFailure(e));
  }
}

export function* DepositSaga() {
  yield takeEvery(DepositActionTypes.FETCH_DEPOSIT_REQUEST, handleFetchDeposit);
  yield takeEvery(
    DepositActionTypes.RESTART_DEPOSIT_REQUEST,
    handleRestratDeposit
  );
}
