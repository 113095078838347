import HttpHelper from "../../helpers/httpHelper";
import { getDepositUrl, getWebhookDepositUrl } from "./deposit.uri";

class DepositAPI {
  static async GetDeposits(req) {
    const { status, startDate, endDate } = req;
    let url = getDepositUrl(status, startDate, endDate);
    return HttpHelper.httpRequest(url, "GET", {});
  }

  static async SendHook(id, hash, address) {
    let url = getWebhookDepositUrl(id, hash, address);
    return HttpHelper.httpRequest(url, "PATCH", {});
  }
}

export default DepositAPI;
