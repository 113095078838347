import HttpHelper from "../../helpers/httpHelper";
import {
  getWebhookSendUrl,
  getWithdrawUrl,
  restartTransactionUrl,
} from "./withdraw.uri";

class WithdrawAPI {
  static async GetWithdraws(req) {
    const { status, startDate, endDate } = req;
    let url = getWithdrawUrl(status, startDate, endDate );
    return HttpHelper.httpRequest(url, "GET", {});
  }

  static async SendHook(id) {
    let url = getWebhookSendUrl(id);
    return HttpHelper.httpRequest(url, "PATCH", {});
  }

  static async RestartTransaction(id) {
    let url = restartTransactionUrl(id);
    return HttpHelper.httpRequest(url, "PATCH", {});
  }
}

export default WithdrawAPI;
